/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */

$(document).ready(function () {
    $(document).on('click', '.cert-download', function () {  
        var _this = this;
        
        $(_this).closest('.modal').animate({ scrollTop: '90px' }, function(){
            html2canvas($(_this).closest('.modal').find('.cert-main'), {
                onrendered: function(canvas) {
                    Canvas2Image.saveAsPNG(canvas);
                }
            });
        });
    });
});
