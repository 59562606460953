    function Comments() {}
    
    Comments.fn = {
        init: function() {         
            var _this = this;  
            var atrs = {
                root: '#comments-container',
                token: '#token',
                commentId: '#comment-id',
                commentUrlStore: '#comment-store-url',
                commentUrlDelete: '#comment-delete-url',
                commentUrlVote: '#comment-vote-url',
                commentUrlGetUsers: '#comment-get-users-url',
                commentUrlGetComments: '#comment-get-comments-url'
            };                         

            if(typeof currentUserId !== 'undefined') {
                var socket = io('http://gxd.edu.vn:3000');

                Comments.fn.initSocket.call(_this, atrs, socket);
                Comments.fn.initComment.call(_this, atrs, socket);
            }       
        },   
    
        initComment: function(atrs, socket) {
            if(typeof profilePictureURL !== 'undefined' && typeof currentUserId !== 'undefined') {                
                $(atrs.root).comments({
                    profilePictureURL: profilePictureURL,
                    currentUserId: currentUserId,
                    roundProfilePictures: true,
                    textareaRows: 2,
                    enableAttachments: true,
                    enableHashtags: true,
                    enablePinging: true,
                    postCommentOnEnter: true,                    
                    noCommentsText: 'Không có bình luận',
                    noAttachmentsText: 'Không có tệp đính kèm',
                    attachmentDropText: 'Xóa tệp đính kèm',
                    viewAllRepliesText: 'Xem (__replyCount__) trả lời',
                    hideRepliesText: 'Ẩn trả lời',
                    deleteText: 'Xóa',
                    saveText: 'Lưu',
                    youText: 'Tôi',
                    editedText: 'Đã sửa',
                    editText: 'Sửa',
                    replyText: 'Trả lời',
                    sendText: 'Gửi',
                    attachmentsText: 'Đính kèm',
                    popularText: 'Bình luận phổ biến',
                    oldestText: 'Bình luận cũ nhất',
                    newestText: 'Bình luận mới nhất',
                    textareaPlaceholderText: 'Viết bình luận',
                    getUsers: function(success, error) {                                                                        
                        var token = $(atrs.token).val();
                        var getUsersUrl = $(atrs.commentUrlGetUsers).val();
                        var postData = {
                            _token: token
                        };

                        Comments.fn.ajax(getUsersUrl, 'POST', postData, 'JSON', function(response){                                        
                            var usersArray = response.data;
                            
                            success(usersArray);
                        });                             
                    },
                    getComments: function(success, error) {
                        var commentId = $(atrs.commentId).val();                                                         
                        var token = $(atrs.token).val();
                        var getCommentsUrl = $(atrs.commentUrlGetComments).val();
                        var postData = {
                            _token: token,
                            id: commentId
                        };
                        
                        Comments.fn.ajax(getCommentsUrl, 'POST', postData, 'JSON', function(response){            
                            var commentsArray = response.data;

                            success(commentsArray);
                        });         
                    },
                    postComment: function(data, success, error) { 
                        var commentId = $(atrs.commentId).val(); 
                        var resData = Comments.fn.saveComment(atrs, data);

                        socket.emit('send', { chanel: 'room_' + commentId, data: resData, type: 'add' });                                                      
                        success(resData);
                    },
                    putComment: function(data, success, error) {
                        var commentId = $(atrs.commentId).val(); 
                        var resData = Comments.fn.saveComment(atrs, data);

                        socket.emit('send', { chanel: 'room_' + commentId, data: resData, type: 'edit' });                          
                        success(resData);
                    },
                    deleteComment: function(data, success, error) {
                        var commentId = $(atrs.commentId).val(); 
                        var token = $(atrs.token).val();
                        var deleteUrl = $(atrs.commentUrlDelete).val();   
                        var postData = {
                            _token: token,
                            id: data.id
                        };
                
                        Comments.fn.ajax(deleteUrl, 'POST', postData, 'JSON', function(response){            
                            if(response.success === true) {
                                socket.emit('send', { chanel: 'room_' + commentId, data: postData, type: 'delete' });  
                                success();                                                                
                            } else {
                                error();              
                            }
                        });         
                    },
                    upvoteComment: function(data, success, error) {
                        var token = $(atrs.token).val();
                        var commentId = $(atrs.commentId).val();     
                        var voteUrl = $(atrs.commentUrlVote).val();   

                        data._token = token;                            
                
                        Comments.fn.ajax(voteUrl, 'POST', data, 'JSON', function(response){            
                            if(response.success === true) {                                                                    
                                socket.emit('send', { chanel: 'room_' + commentId, data: data, type: 'vote' });  
                                success(data);                                    
                            }
                        });          
                    },
                    uploadAttachments: function(commentArray, success, error) {                         
                        $(commentArray).each(function(index, commentJSON) {     
                            var token = $(atrs.token).val();
                            var commentId = $(atrs.commentId).val();     
                            var storeUrl = $(atrs.commentUrlStore).val();                                   
                            
                            // Create form data
                            var formData = new FormData();
                            $(Object.keys(commentJSON)).each(function(index, key) {
                                var value = commentJSON[key];
                                if(value) formData.append(key, value);
                            });

                            formData.append('_token', token);
                            formData.append('commentId', commentId);
                
                            $.ajax({
                                url: storeUrl,
                                type: 'POST',
                                data: formData,
                                cache: false,
                                contentType: false,
                                processData: false,
                                success: function(response) {
                                    if(response.success === true) {
                                        var resData = response.data.comment;
                                        var commentObj = {
                                            "id": resData.id,
                                            "parent": resData.parent_id,
                                            "created": resData.created_at,
                                            "modified": resData.updated_at,
                                            "file_url": '/frontend/comment/' + resData.content,
                                            "file_mime_type": resData.file_mime_type,
                                            "pings": [],
                                            "creator": resData.created_by,
                                            "fullname": commentJSON.fullname,
                                            "profile_picture_url": commentJSON.profile_picture_url,
                                            "created_by_admin": false,
                                            "created_by_current_user": true,
                                            "upvote_count": resData.vote,
                                            "user_has_upvoted": false,
                                            "is_new": false
                                        };

                                        var dataArrs = [];

                                        dataArrs.push(commentObj);
                                        socket.emit('send', { chanel: 'room_' + commentId, data: commentObj, type: 'attach' });                                           

                                        success(dataArrs);
                                    } else {
                                        error();
                                    }
                                }
                            });
                        });
                    }
                });
            }
        },   
            
        initSocket: function (atrs, socket) {    
            var _this = this;                                    
            var commentId = $(atrs.commentId).val();  
    
            socket.emit('save-client', { id: currentUserId });
            socket.emit('subscribe', { chanel: 'room_' + commentId });
            socket.on('room', function(data) {
                if(data.type === 'add') {
                    data.data.created_by_current_user = false;                    
                    commentsArray.push(data.data);                                            
                } else if(data.type === 'edit') {
                    commentsArray.forEach(function(item, index){
                        if(item.id === data.data.id) {
                            data.data.created_by_current_user = false;
                            commentsArray[index] = data.data;
                        }
                    });
                } else if(data.type === 'delete') {
                    commentsArray.forEach(function(item, index){
                        if(item.id === data.data.id) {
                            commentsArray.splice(index, 1);
                        }
                    });
                } else if(data.type === 'attach') {
                    data.data.created_by_current_user = false;
                    commentsArray.push(data.data);  
                } else if(data.type === 'vote') {                    
                    commentsArray.forEach(function(item, index){
                        if(item.id === data.data.id) {
                            data.data.created_by_current_user = false;
                            commentsArray[index] = data.data;
                        }
                    });
                }

                Comments.fn.initComment.call(_this, atrs, socket);
            });
        },   

        saveComment: function(atrs, data) {
            // Convert pings to human readable format
            $(data.pings).each(function(index, id) {
                var user = usersArray.filter(function(user) {
                    return user.id == id;
                })[0];
                
                data.content = data.content.replace('@' + id, '@' + (typeof user.fullname != 'undefined') ? user.fullname : user.email);
            });
            
            var token = $(atrs.token).val();
            var commentId = $(atrs.commentId).val();     
            var storeUrl = $(atrs.commentUrlStore).val();   
            var postData = {
                _token: token,
                commentId: commentId,
                data: data
            };
    
            Comments.fn.ajax(storeUrl, 'POST', postData, 'JSON', function(response){            
                if(response.success === true) {
                    var resData = response.data.comment;
    
                    data.fullname = resData.fullname;
                    data.id = resData.id;                
                }
            });     
            
            return data;
        },
 
        ajax: function(url, type, data, dataType, response) {
            $.ajax({
                url: url,
                type: type,
                dataType: dataType,
                data: data,
                async: false,
                success: function(rs)
                {
                    response(rs);
                }
            });
        },       
        
        action: function(event, obj, callback) {
            $(document).on(event, obj, function(e) {
                callback($(this), e);
            });
        },
        
        rule: function() {
            $(document).ready(function(){ 
                Comments.fn.init.call(this);                        
            });
        }
    };
    
    Comments.fn.rule();
