/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */

if ($(".header-wrapper").length) {
    $(".header-wrapper").backstretch($('.header-wrapper').attr('bg'));
}

if ($(".footer-wrapper").length) {
    $(".footer-wrapper").backstretch($('.footer-wrapper').attr('bg'));
}

// (function () {
//     $(function () {
//         $('.max-height-item').matchHeight();
//         $('.max-height-title-item').matchHeight();
//     });
// })();

$(function() {
    // Tooltip
    if ($('[init=tooltip]').length) {
        $('[init=tooltip]').each(function() {
            var self = this;
            $(self).tooltip();
        });
    }

    if ($('[init="ckeditor"]').length) {
        $('[init="ckeditor"]').each(function() {
            var self = this;
            
            if(typeof $(self).attr('name') !== 'undefined') {
                CKEDITOR.plugins.addExternal('youtube', '/../../builds/theme/js/ckeditor-youtube-plugin/youtube/');
                CKEDITOR.replace($(self).attr('name'), { 
                    extraPlugins:'youtube',
                    filebrowserBrowseUrl : '/filemanager/dialog.php?type=2&editor=ckeditor&fldr=', 
                    filebrowserUploadUrl : '/filemanager/dialog.php?type=2&editor=ckeditor&fldr=', 
                    filebrowserImageBrowseUrl : '/filemanager/dialog.php?type=1&editor=ckeditor&fldr=' 
                });
            } else {
                var editor = $(self).ckeditor();
            }                                   
        });
    }
    
    if ($('.type-datepicker').length) { 
        $('.type-datepicker').datepicker({format: 'yyyy-mm-dd'});
    }  
    
    if ($('.f-datepicker').length) { 
        $('.f-datepicker').datepicker({format: 'dd/mm/yyyy'});
    }  
    
    if ($('[init="select2-cert"]').length) {
        $('[init="select2-cert"]').select2();
    }

    $('.sortable-area').map(function(){
        $(this).sortable({
            containerSelector: 'div[data-group="type-sort"]',
            group: '.sort-row',
            itemSelector: '.sort-row',
            handle: 'i.fa-arrows',
            placeholder:'<div class="placeholder" style="background: antiquewhite; width: 100%; height: 20px; margin-bottom: 15px; display: block;"></div>'         
        });
    });
});