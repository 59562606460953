/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */

$(document).ready(function(){
//    $('.scroll-content').niceScroll({autohidemode: false});
//    $('.scroll-content').getNiceScroll().resize();

    if($('.lession-active').length) {
        $('.tab-content.scroll-content').animate({
            scrollTop: $('.lession-active').offset().top-53
        }, 2000);
    }    
    
    function loadScroll(cls) {
        var content = $(cls);       
        
        if(content.length) {
            content.slimScroll({
                position: 'right',
                color: '#000',
                size: '8px',
                height: '100%',
                railVisible: true,
                alwaysVisible: true,
                allowPageScroll: true
            });

            content.mouseover();   
        }                
    }
    
    setTimeout(function(){ 
        //loadScroll(".cover-course-learn .ccl-content .ccl-content-body"); 
        loadScroll(".fix-info-description .fix-info-content"); 
    }, 500);    
    
    $(document).on('click', '.fix-info', function(){
        if($(this).attr('status') !== 'on') {           
            $(this).attr('status', 'on');
            $('.fix-info-description').show();
            $(this).hide();
        }
    });
    
    $(document).on('click', '.fix-info-close', function(){
        if($('.fix-info').attr('status') === 'on') {
            $('.fix-info').attr('status', 'off');
            $('.fix-info-description').hide();
            $('.fix-info').show();
        } 
    });

    if($('.clock').length) {
        $('.clock').map(function(){
            var clock;			
            var time = $(this).attr('data-time');

            clock = $(this).FlipClock(time * 60, {
                clockFace: 'MinuteCounter',
                countdown: true,
                callbacks: {
                    stop: function() {
                        $('#quiz-form').submit();
                    }
                }
            });
        });
    }    

    if($('.mlink-menu').length) {
        $(document).on('click', '.mlink-menu', function(){
            $('.cover-course-learn .ccl-tabs').toggle();
        });
    }
});
