function QuestionTemplate() {
}

QuestionTemplate.fn = {
    init: function() {
        QuestionTemplate.fn.handle.call(this);
        QuestionTemplate.fn.print.call(this);
    },
    handle: function() {
        var loadItem = function(key, _this) {
            $('[data-type="menu"][key="'+ key +'"]')
                .removeClass('btn-info')
                .removeClass('btn-danger')
                .removeClass('btn-default')
                .removeClass('btn-warning');
            
            var flag = false;

            if(_this) {
                var warningBtn = _this.find('.gs-answer');
                var input = _this.find('.box-ans input.txt-ans').first();            
                var type = input.attr('type');
                var name = input.attr('name');
                
                if(warningBtn.length > 0) {
                    if(warningBtn[0].checked) {
                        flag = true;
                        $('[data-type="menu"][key="'+ key +'"]').addClass('btn-warning');                   
                    } 
                }                
    
                var val = $('input[name="'+ name +'"]:checked').val();
                
                if(typeof val != 'undefined' && flag == false) {
                    flag = true;
                    $('[data-type="menu"][key="'+ key +'"]').addClass('btn-default');                
                }
            }
                        
            if(flag == false) {
                $('[data-type="menu"][key="'+ key +'"]').addClass('btn-info');
            }

            $('[active="true"][data-type="menu"]').addClass('btn-danger');
        };

        var loadAll = function() {
            $('[data-type="menu-item"]').map(function(){
                var key = $(this).attr('key');
                loadItem(key, $(this));
            });
        };

        loadAll();
        
        $(document).on('click', '[data-type="menu"]', function(){
            var _this = $(this);
            var key = _this.attr('key');
            var firstElm = $('[data-type="menu"]').first();
            var lastElm = $('[data-type="menu"]').last();
            var firstKey = firstElm.attr('key');
            var lastKey = lastElm.attr('key');

            if(firstKey == lastKey) {
                $('[data-type="next"]').hide();
                $('[data-type="prev"]').hide();
            } else {
                if(key > firstKey && key < lastKey) {
                    $('[data-type="next"]').attr('disabled', false);
                    $('[data-type="prev"]').attr('disabled', false);
                }

                if(firstKey == key) {
                    $('[data-type="next"]').attr('disabled', false);
                    $('[data-type="prev"]').attr('disabled', true);
                }

                if(lastKey == key) {
                    $('[data-type="next"]').attr('disabled', true);
                    $('[data-type="prev"]').attr('disabled', false);
                }
            }

            $('[active="true"][data-type="menu"]').attr('active', false);
            _this.attr('active', true);
            loadAll();

            $('[data-type="menu-item"]').hide();
            $('[data-type="menu-item"][key="'+ key +'"]').show();
        });

        $(document).on('change', '.gs-answer', function(){        
            loadAll();
        });

        $(document).on('change', '[data-type="menu-item"] .box-ans input.txt-ans', function(){
            loadAll();
        });

        $(document).on('click', '[data-type="prev"]', function(){
            var prev = $('[data-type="menu"][active="true"]').prev();

            if (typeof prev.attr('data-type') !== typeof undefined && prev.attr('data-type') == 'menu') {
                prev.trigger('click');
            }
        });

        $(document).on('click', '[data-type="next"]', function(){
            var next = $('[data-type="menu"][active="true"]').next();

            if (typeof next.attr('data-type') !== typeof undefined && next.attr('data-type') == 'menu') {
                next.trigger('click');
            }
        });
    },
    print: function() {        
        $(document).on('click', '.exam-result', function(){
            $('.exam-result-area').show();
        });

        $(document).on('click', '[data-type="print"]', function(){
            $('.print-none').attr('style', 'visibility:hidden;');
            window.print();
        });

        $(document).on('click', '[data-type="back-lesson"]', function(){
            $('.print-none').attr('style', 'visibility:visible;');
            $('.exam-result-area').hide();
        });

        var beforePrint = function() {
            
        };
    
        var afterPrint = function() {
            $('.print-none').attr('style', 'visibility:visible;');
        };
    
        if (window.matchMedia) {
            var mediaQueryList = window.matchMedia('print');
            mediaQueryList.addListener(function(mql) {
                if (mql.matches) {
                    beforePrint();
                } else {
                    afterPrint();
                }
            });
        }
    
        window.onbeforeprint = beforePrint;
        window.onafterprint = afterPrint;
    },
    rule: function() {
        $(document).ready(function() {
            QuestionTemplate.fn.init.call(this);
        });
    }
};

QuestionTemplate.fn.rule();